<template>
  <div id="voucher-category">
    <div class="p-5 bg-gray-300" style="margin-top: 90px;">
      <a
        class="flex flex-col mt-2.5 pt-4 pb-2"
        href="/vouchers/search"
      >
        <SearchInputField
          id="search-voucher"
          class="w-full"
          font="text-xs lg:text-sm"
          placeholder="Wonach suchst Du?"
          :submitButton="false"
          :iconLeft="true"
          :fullWidthItem="true"
        />
        <SearchInputField
          id="search-voucher"
          class="w-full"
          font="text-xs lg:text-sm"
          placeholder="Innsbruck"
          :submitButton="false"
          :iconLeft="true"
          :isMapIcon="true"
          :fullWidthItem="true"
        />
      </a>
    </div>
    <div
      class="bg-white overflow-hidden relative w-full h-32 md:h-80"
    >
      <img
        v-if="CATEGORY && CATEGORY.icon.indexOf('fas') == -1"
        :src="onSetImage(CATEGORY.icon)"
        class="bg-white w-full bg-cover bg-center"
      />
      <img class="bg-white h-full mx-auto" src="/placeholder-1080.jpg" v-else />
      <div
        class="absolute flex justify-center text-white w-1/2 bg-white font-bold text-peach mx-auto h-14 text-4xl bottom-0 left-0 right-0 bg-opacity-75"
      >
        {{ CATEGORY ? CATEGORY.name : 'Category Name' }}
      </div>
    </div>

    <div class="bg-white py-10">
      <div class="max-w-2xl mx-auto w-11/12">
        <h2 class="font-bold mb-4 text-3xl text-center text-peach">
          UNSERE ERLEBNISSE:
        </h2>
        <div class="flex flex-wrap gap-2 justify-center">
          <a
            v-for="(category, index) in CATEGORIES"
            :key="`item-${index}`"
            :href="`/vouchers/category/${$helpers.toSlug(category.name)}`"
            class="border border-peach flex font-medium hover:bg-peach hover:text-white items-center px-10 py-3"
          >
            <p>{{ category.name }}</p>
          </a>
        </div>

        <p class="text-center mt-20 text-2xl italic text-gray-500">
          Du suchst etwas Bestimmtes?
        </p>
      </div>

      <div class="flex justify-center">
        <button
          type="button"
          class="border flex items-center mb-2 mr-3 py-2 rounded-md bg-peach text-white px-5 font-semibold"
          @click="showFilter = true"
        >
          <svg
            class="border icon mr-2 rounded-full text-white border-white h-5 w-5"
          >
            <use xlink:href="/icons/sprite.svg#filter" />
          </svg>
          Filter/ Sotieren
        </button>
      </div>
    </div>

    <VouchCategory
      title="Unsere beliebtesten Gutscheine:"
      category="Sport & Adventure"
      :showButton="false"
      :theme="'peach'"
      :isMinimal="true"
    />

    <VouchCategory
      category="Sport & Adventure"
      :showButton="false"
      :theme="'gray'"
      :isMinimal="true"
    />

    <VouchCategory
      title="Zillertaler Flugschule"
      description="In ein Abenteuer stürzen und die Welt von oben sehen mit der Zillertaler Flugschule."
      category="Sport & Adventure"
      :showButton="false"
      :theme="'peach'"
    >
      <div class="bg-black bg-opacity-25 w-full md:mx-10 md:w-1/2">
        <div style="padding:100% 0 0 0;position:relative;">
          <iframe
            src="https://player.vimeo.com/video/595807760?autoplay=1&autopause=0&controls=0&muted=1&loop=1"
            style="position:absolute;top:0;left:0;width:100%;height:100%;"
            frameborder="0"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </VouchCategory>

    <MainLayout>
      <template #content>
        <div
          v-if="!IS_LOADING.status"
          class="flex flex-col mx-auto px-8 w-full"
          style="max-width: 1200px; background: #F2F2F2;"
        >
          <VoucherList
            class="mb-3"
            title=""
            :data="VOUCHERS.data"
            :withPagination="true"
            :currentPage="VOUCHERS.current_page"
            :lastPage="VOUCHERS.last_page"
            sortLabel="Sortieren nach:"
            :withSort="false"
            filterLabel="Filtern nach:"
            :withFilter="false"
            :withQR="false"
            listId="search-voucher-list"
            :hasCategory="false"
            @onChange="onLoadData"
            @onFilter="onSearchData($event, 'filter')"
            @onSort="onSearchData($event, 'sort')"
            @onPaginate="onPaginateVouchers($event)"
          />

          <div
            class="bg-white bottom-0 fixed filter-sidebar transition-all duration-500 shadow-2xl top-0 overflow-auto flex flex-col"
            :class="{ show: showFilter }"
          >
            <div class="p-8 overflow-auto">
              <div
                class="border-b-2 flex items-center justify-between pb-4 mb-4"
              >
                <div class="flex font-medium items-center text-xl">
                  <svg
                    class="border border-peach h-6 icon mr-2 p-1 rounded-full text-peach w-6"
                  >
                    <use xlink:href="/icons/sprite.svg#filter" />
                  </svg>
                  Filter
                </div>
                <div class="flex items-center text-sm">
                  Schließen
                  <svg
                    @click="showFilter = false"
                    class="h-4 icon ml-2 mr-6 w-4 cursor-pointer"
                  >
                    <use xlink:href="/icons/sprite.svg#x-circle" />
                  </svg>
                </div>
              </div>
              <!-- PRICE -->
              <section class="border-b-2 mb-4 pb-4">
                <div class="font-medium text-xs mb-2">Preis</div>
                <div class="grid grid-cols-2 gap-2">
                  <input
                    v-model="params.isPrice.from"
                    type="number"
                    placeholder="von"
                    step="any"
                    class="border mt-2 px-3 py-2 rounded-md text-xs"
                  />

                  <input
                    v-model="params.isPrice.to"
                    type="number"
                    placeholder="bis"
                    step="any"
                    class="border mt-2 px-3 py-2 rounded-md text-xs"
                  />
                </div>
              </section>
              <!-- End PRICE -->
              <!-- SORT -->
              <section class="border-b-2 mb-4 pb-4">
                <div class="font-medium text-xs mb-2">Sortieren</div>
                <div>
                  <button
                    type="button"
                    class="px-2 py-2 rounded-md text-xs mr-3 mb-2 border border-black"
                    :class="
                      params.isNewest ? 'bg-black text-white ' : ' text-black'
                    "
                    @click="onSort(['isNewest'])"
                  >
                    Neueste
                  </button>
                  <button
                    type="button"
                    class="px-2 py-2 rounded-md text-xs mr-3 mb-2 border border-black"
                    :class="
                      params.isMostPopular
                        ? 'bg-black text-white '
                        : ' text-black'
                    "
                    @click="onSort(['isMostPopular'])"
                  >
                    Beliebteste
                  </button>
                  <button
                    type="button"
                    class="px-2 py-2 rounded-md text-xs mr-3 mb-2 border border-black"
                    :class="
                      params.isLowestPrice
                        ? 'bg-black text-white '
                        : ' text-black'
                    "
                    @click="onSort(['isLowestPrice'])"
                  >
                    {{ !params.isLowestPrice ? 'Günstigster' : 'Günstigster' }}
                    Preis
                  </button>
                </div>
              </section>
              <!-- End SORT -->
              <!-- REGION -->
              <section class="border-b-2 mb-4 pb-4">
                <div class="font-medium text-xs mb-2">Region</div>
                <div>
                  <button
                    v-for="(region, index) in REGIONS"
                    :key="index"
                    type="button"
                    class="px-2 py-2 rounded-md text-xs mr-3 mb-2 border border-black"
                    :class="
                      params.isRegion.indexOf(region.label) != -1
                        ? 'bg-black text-white'
                        : 'text-black'
                    "
                    @click="onChangeRegion(region.label)"
                  >
                    {{ region.label }}
                  </button>
                </div>
              </section>
              <!-- End REGION -->
              <!-- CATEGORY -->
              <section class="mb-4 pb-4">
                <div class="font-medium text-xs mb-2">Unterkategorien</div>
                <div v-if="SUBCATEGORIES && SUBCATEGORIES.length">
                  <span class="flex items-center mb-3 text-xs">
                    <svg class="icon h-4 w-4 mr-2">
                      <use
                        :xlink:href="`/icons/sprite.svg#chevron-right`"
                      /></svg
                    >{{ currentCategory.name }}
                  </span>
                  <div class="ml-6">
                    <button
                      v-for="(category, index) in SUBCATEGORIES"
                      :key="index"
                      type="button"
                      class="px-2 py-2 rounded-md text-xs mr-3 mb-2 border border-black"
                      :class="
                        params.subcategory.indexOf(category.id) !== -1
                          ? 'bg-black text-white'
                          : 'text-black'
                      "
                      @click="onChangeData('subcategory', category.id)"
                    >
                      {{ category.name }}
                    </button>
                  </div>
                </div>
              </section>
              <!-- End CATEGORY -->
              <!-- Fur -->
              <section class="mb-4 pb-4">
                <div class="font-medium text-xs mb-2">Für</div>
                <div>
                  <button
                    v-for="(target, index) in target_group"
                    :key="index"
                    type="button"
                    class="px-2 py-2 rounded-md text-xs mr-3 mb-2 border border-black"
                    :class="
                      params.targets.indexOf(target) !== -1
                        ? 'bg-black text-white'
                        : 'text-black'
                    "
                    @click="onChangeData('targets', target)"
                  >
                    {{ target }}
                  </button>
                </div>
              </section>
              <!-- End Fur -->
              <!-- Wetter / Saison -->
              <section class="mb-4 pb-4">
                <div class="font-medium text-xs mb-2">Wetter / Saison</div>
                <div>
                  <button
                    v-for="(season, index) in seasons"
                    :key="index"
                    type="button"
                    class="px-2 py-2 rounded-md text-xs mr-3 mb-2 border border-black"
                    :class="
                      params.seasons.indexOf(season) !== -1
                        ? 'bg-black text-white'
                        : 'text-black'
                    "
                    @click="onChangeData('seasons', season)"
                  >
                    {{ season }}
                  </button>
                </div>
              </section>
              <!-- End Wetter / Saison -->
            </div>
            <button
              type="button"
              @click="onSearchData"
              class="company-bg-color px-5 py-3 text-sm text-white mt-3 text-center"
            >
              Ergebnisse Anzeigen
            </button>
          </div>
        </div>

        <div class="bg-white py-10" style="margin-bottom: -35px">
          <div class="max-w-2xl mx-auto w-11/12">
            <h2 class="font-bold mb-4 text-3xl text-center text-peach">
              UNSERE ERLEBNISSE:
            </h2>
            <div class="flex flex-wrap gap-2 justify-center">
              <a
                v-for="(category, index) in CATEGORIES"
                :key="`item-${index}`"
                :href="`/vouchers/category/${$helpers.toSlug(category.name)}`"
                class="border border-peach flex font-medium hover:bg-peach hover:text-white items-center px-10 py-3"
              >
                <p>{{ category.name }}</p>
              </a>
            </div>
          </div>
        </div>
      </template>
    </MainLayout>
  </div>
</template>
<script>
import MainLayout from '_layouts';
import VoucherList from '_components/List/Modules/VoucherList/';
import Header1 from '_components/Headers/Header1';
import SearchInputField from '_components/Form/SearchInputField';
import VouchCategory from '_components/VouchCategory';

export default {
  name: 'VoucherCategory',
  components: {
    MainLayout,
    VoucherList,
    Header1,
    SearchInputField,
    VouchCategory,
  },
  data() {
    return {
      currentCategory: null,
      showFilter: false,
      target_group: [
        'Paare',
        'Freunde',
        'Kinder',
        'Frauen',
        'Männer',
        'Senioren',
      ],
      seasons: ['Sommer', 'Winter', 'Schönwetter', 'Schlechtwetter'],
      params: {
        page: 1,
        paginate: 9,
        isNewest: false,
        isLowestPrice: false,
        isMostPopular: false,
        seed: new Date().getTime(),
        isCategory: [],
        isRegion: [],
        isPrice: {
          from: '',
          to: '',
        },
        targets: [],
        seasons: [],
        subcategory: [],
      },
    };
  },
  computed: {
    AUTH_USER() {
      return this.$store.getters.AUTH_USER;
    },
    VOUCHERS() {
      return this.$store.getters.VOUCHERS;
    },
    FEATURED_VOUCHERS() {
      return this.$store.getters.FEATURED_VOUCHERS;
    },
    NEWEST_VOUCHERS() {
      return this.$store.getters.NEWEST_VOUCHERS;
    },
    CATEGORY() {
      return this.$store.getters.CATEGORY;
    },
    CATEGORIES() {
      return this.$store.getters.CATEGORIES;
    },
    IS_LOADING() {
      return this.$store.getters.IS_LOADING;
    },
    IS_LOAD_MORE() {
      return this.$store.getters.IS_LOAD_MORE;
    },
    IS_PROCESSING() {
      return this.$store.getters.IS_PROCESSING;
    },
    REGIONS() {
      return this.$store.getters.REGIONS;
    },
    SUBCATEGORIES() {
      let selectCategory = this.CATEGORIES.find(
        (category) => category.id == this.currentCategory.id
      );
      return selectCategory.subcategories;
    },
    // IS_INFINITE_LOAD()
    // {
    //   return this.$store.getters.IS_INFINITE_LOAD
    // },
  },
  watch: {
    async '$route.params.slug'() {
      await this.$store.commit('SET_FEATURED_VOUCHERS', []);
      await this.$store.commit('SET_NEWEST_VOUCHERS', []);
      await this.$store.commit('SET_VOUCHERS', []);
      // await this.$store.commit('SET_IS_INFINITE_LOAD', true)
      this.params = {
        page: 1,
        paginate: 5,
        isNewest: true,
        isLowestPrice: false,
        isMostPopular: false,
        isCategory: [],
        isRegion: [],
      };
      await this.onFetchData();
    },

    // async IS_LOAD_MORE(newVal)
    // {
    //   if( newVal ) {
    //     await this.onLoadData({
    //       ...this.params,
    //       page: this.params.page + 1
    //     })
    //     await this.$store.commit('SET_IS_LOAD_MORE', false)
    //   }
    // },
  },
  mounted() {
    (async () => {
      // await this.$store.commit('SET_IS_INFINITE_LOAD', true)
      await this.$store.commit('SET_FEATURED_VOUCHERS', []);
      await this.$store.commit('SET_NEWEST_VOUCHERS', []);
      await this.$store.commit('SET_VOUCHERS', []);

      await this.onFetchData();
    })();
  },
  beforeDestroy() {
    (async () => {
      await this.$store.commit('SET_IS_INFINITE_LOAD', false);
    })();
  },
  methods: {
    async onPaginateVouchers(action) {
      let params = {
        ...this.params,
        page: action === 'prev' ? this.params.page - 1 : this.params.page + 1,
      };
      await this.$store.commit('SET_VOUCHERS', []);
      await this.onLoadData(params);
    },
    async onSearchData(data = null, action) {
      if (action == 'sort') {
        this.params.keyword = '';
      }
      let params =
        action == 'sort' || action == 'filter'
          ? {
              ...this.params,
              ...data,
              page: 1,
            }
          : {
              ...this.params,
              page: 1,
            };

      if (params.isPrice.from && params.isPrice.to) {
        params.isPrice = {
          from: parseFloat(params.isPrice.from),
          to: parseFloat(params.isPrice.to),
        };
      } else {
        params.isPrice = [];
      }

      this.showFilter = false;

      await this.onLoadData(params);
    },
    async onFetchData() {
      try {
        await this.$store.commit('SET_IS_LOADING', { status: 'open' });
        await this.onFetchCategory();
        await this.onFetchFeaturedVouchers();
        await this.onFetchNewestVouchers();
        await this.$store.commit('SET_IS_LOADING', { status: 'close' });
      } catch (err) {
        await this.$store.commit('SET_IS_LOADING', { status: 'close' });
      }
    },
    async onLoadData(data) {
      await this.$store.commit('SET_IS_PROCESSING', { status: 'open' });
      this.params = {
        ...this.params,
        ...data,
        isCategory: [this.CATEGORY.name],
      };
      await this.onFetchNewestVouchers();
      await this.$store.commit('SET_IS_PROCESSING', { status: 'close' });
    },
    async onFetchFeaturedVouchers() {
      try {
        await this.$store.commit('SET_VOUCHERS', []);
        await this.$store.dispatch('FETCH_FEATURED_VOUCHERS', {
          isCategory: this.params.isCategory,
        });
      } catch (err) {
        console.log('err', err);
      }
    },
    async onFetchNewestVouchers() {
      try {
        let params = { ...this.params };
        if (params.isPrice.from && params.isPrice.to) {
          params.isPrice = {
            from: parseFloat(params.isPrice.from),
            to: parseFloat(params.isPrice.to),
          };
        } else {
          params.isPrice = [];
        }

        const data = await this.$store.dispatch(
          'FETCH_SEARCH_VOUCHERS',
          params
        );
        if (data.vouchers.next_page_url == null) {
          await this.$store.commit('SET_IS_INFINITE_LOAD', false);
        }
      } catch (err) {
        console.log('err', err);
      }
    },
    async onFetchCategory() {
      const { voucher_categories: categories } = await this.$store.dispatch(
        'FETCH_CATEGORIES'
      );
      this.currentCategory = categories.find((_category) => {
        let categoryName = this.$helpers.toSlug(_category.name);
        return categoryName == this.$route.params.slug;
      });
      try {
        await this.$store.dispatch('FETCH_CATEGORY', this.currentCategory.id);
        if (this.CATEGORY) {
          this.params.isCategory = [this.CATEGORY.name];
        }
      } catch (err) {
        console.log('err', err);
      }
    },
    onSort(data) {
      this.params = {
        ...this.params,
        isMostPopular: false,
        isNewest: false,
        isLowestPrice: false,
        [data]: !this.params[data],
      };
    },
    onChangeRegion(name) {
      let index = this.params.isRegion.indexOf(name);

      if (index == -1) {
        this.params.isRegion.push(name);
      } else {
        this.params.isRegion.splice(index, 1);
      }
    },
    onChangeCategory(name) {
      let index = this.params.isCategory.indexOf(name);

      if (index == -1) {
        this.params.isCategory.push(name);
      } else {
        this.params.isCategory.splice(index, 1);
      }
    },
    onChangeData(key, name) {
      console.log(key, name);
      let index = this.params[key].indexOf(name);

      if (index == -1) {
        this.params[key].push(name);
      } else {
        this.params[key].splice(index, 1);
      }
    },
    onSetImage(value) {
      return value.search('base64') < 0
        ? `${process.env.VUE_APP_API_BASE_URL}/storage/${value}`
        : value;
    },
  },
};
</script>
<style lang="css" scoped>
.filter-sidebar {
  width: 100%;
  z-index: 99999;
  right: -500px;
}
.filter-sidebar.show {
  right: 0;
}

@screen sm {
  .filter-sidebar {
    max-width: 350px;
  }
}

#voucher-category .guest-container {
  padding-top: 0px !important;
}

#voucher-category #main-layout-component.absolute {
  position: relative !important;
}

@screen md {
  #voucher-category .guest-container {
    padding-bottom: 0px !important;
  }
}
</style>
